@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --primary-color: #111317;
  --primary-color-light: #1f2125;
  --primary-color-extra-light: #35373b;
  --secondary-color: #f9ac54;
  --secondary-color-dark: #d79447;
  --text-light: grey;
  --white: black;
  --max-width: 1300px;
}

body, html {
  overflow-x: hidden;
}


.cookie-reopen-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Asegúrate de que el botón esté encima de otros elementos */
}

.cookie-reopen-button:hover {
  background-color: darkorange;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.loader{
  margin-top: 18%;
  margin-left: 48.5%;
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 4rem 1rem;
}

.section__header {
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
  color: var(--white);
}

.section__subheader {
  max-width: 90%;
  margin: auto;
  text-align: center;
  color: #181414;
}


.section__subheader2 {
  max-width: 600px;
  margin: auto;
  text-align: center;
  color: #de2929;
}

.btn {
  padding: 1rem 2rem;
  text-align: justify;
  width:  100%;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: 0.3s;
}
.btn_header {
  padding: 1rem 2rem;
  text-align: justify;
  width:  100%;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: 0.3s;
}

.btn_diferenciate {
  padding: 1rem 2rem;
  text-align: center;
  width:  40%;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: 0.3s;
}

.btn_nosotrosTeAyudamos {
  padding: 1rem 2rem;
  text-align: center;
  width:  40%;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: 0.3s;
}

.containerSolicitaPresupuesto {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7%;
  margin-bottom: 4%;
}

.btn_solicitaPresupuesto {
  padding: 1rem 2rem;
  text-align: center;
  width: 40%;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: 0.3s;
}


.btn:hover {
  background-color: var(--secondary-color-dark);
}

.btn_nosotrosTeAyudamos:hover {
  background-color: var(--secondary-color-dark);
}

.btn_diferenciate:hover {
  background-color: var(--secondary-color-dark);
}

.btn_solicitaPresupuesto:hover {
  background-color: var(--secondary-color-dark);
}
img {
  width: 100%;
  display: flex;
  
}

.class__img-1{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.class__img-2{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
a {
  text-decoration: none;
}

.bg__blur {
  position: absolute;
  box-shadow: 0 0 1000px 50px var(--secondary-color);
  z-index: -1;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}

nav {
  width: 100%;
  justify-content: center;
  margin: auto;
  padding: 2rem 1rem;
  display: flex;
  position: fixed;
  z-index: 1;
  gap: 2rem;
  background-color: #ffffff;
  margin-top: -7%;
  box-shadow: 0px 0px 10px #f9ac54;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__menu-icon {
  display: none; /* Por defecto, el ícono de hamburguesa estará oculto */
}

.nav__logo {
  max-width: 150px;
}

.nav__links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3rem;
  
}

.link a {
  position: relative;
  padding-bottom: 0.75rem;
  color: var(--white);
  
}

.link a::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  transition: 0.3s;
}

.link a:hover::after {
  width: 50%;
}

.header__container {
  position: relative;
  padding-top: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 5rem;
  margin-top: 7%;
}

.header__container::before {
  position: absolute;
  bottom: 5rem;
  right: 20rem;
  font-size: 10rem;
  font-weight: 700;
  line-height: 7rem;
  color: var(--white);
  opacity: 0.05;
  z-index: -1;
}

.header__blur {
  bottom: 5rem;
  right: 0;
}

.header__content h4 {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  
}

.header__content h1 {
  margin-bottom: 1rem;
  font-size: 5rem;
  font-weight: 700;
  line-height: 6rem;
  color: var(--white);
  
}

.header__content h1 span {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #f9ac54;
}

.header__content p {
  margin-bottom: 2rem;
  color: black
}

.header__image {
  position: relative;
  padding-top: 110px;
  height: auto;
  width: 100%;
}

.footer__image {
  position: right;
  padding-top: 5px;
  width: 10%;
  
}

.header__image::before {
  content: "";
  background-image: url('assets/logoM.png'); /* Ruta de tu imagen */
  background-size: cover; /* Ajusta el tamaño de la imagen para que cubra todo el contenedor */
  mask-image: linear-gradient(black 80%,transparent);
  padding-top: 10px;
  position: absolute;
  top: 0;
  left: 70%;
  transform: translateX(-50%);
  width: 90%; /* Ancho de la imagen */
  height: 90%; /* Alto de la imagen */
  opacity: 0.1;
  z-index: -1;
}

.header__image img {
  max-width: 350px;
  margin: auto;
  width: 100%;
}

.explore__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.explore__nav {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.explore__nav span {
  padding: 0 6px;
  font-size: 1.5rem;
  color: var(--white);
  border: 2px solid var(--white);
  border-radius: 100%;
  cursor: pointer;
  transition: 0.3s;
}

.explore__nav span:hover {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.explore__grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}

.explore__card {
  padding: 1rem;
  text-align: center;
  background-color: white;
  border: 2px solid orange;
  border-radius: 25px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
}

.explore__card:hover {
  background-color: var(--primary-color-light);
  border-color: var(--secondary-color);
  transform: translateY(-10px);
}

.explore__card span {
  display: inline-block;
  padding: 2px 9px;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  color: var(--white);
  background-color: var(--secondary-color-dark);
  border-radius: 5px;
}

.explore__card h4 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--secondary-color);
}

.explore__card p {
  margin-bottom: 1rem;
  color: var(--text-light);
}

.explore__card a {
  color: var(--white);
  transition: 0.3s;
}

.explore__card a:hover {
  color: var(--secondary-color);
}

.class__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.class__image {
  position: relative;
}

.class__image .class__img-1 {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 500px;
  border-radius: 10px;
  border-radius: 5px solid;
  border: 2px solid #0000;
  background:
    linear-gradient(black, #222) padding-box,
    linear-gradient(#f9bd59, #ffde59) border-box;
}

.class__image .class__img-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 300px;
  border-radius: 10px;
  border-radius: 5px solid;
  border: 2px solid #0000;
  background:
    linear-gradient(black, #222) padding-box,
    linear-gradient(#f9bd59, #ffde59) border-box;
}

.class__content {
  padding: 2rem 0;
}

.class__content .section__header {
  text-align: left;
  max-width: 400px;
}

.class__content p {
  margin-bottom: 4rem;
  color: var(--text-light);
}

.join__image {
  margin-top: 4rem;
  position: relative;
}

.join__image img {
  border-radius: 10px;
}

.join__grid {
  position: absolute;
  bottom: -7rem;
  width: calc(100% - 4rem);
  padding: 2rem;
  margin: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  background-color: var(--primary-color-light);
  border-radius: 25px;
  box-shadow: rgb(236, 166, 63) 0px 3px 8px; 
  transition: transform 250ms;
}

.join__grid:hover{
  transform: translateY(-10px);
}

.join__card {
  flex: 1 1 250px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.join__card span {
  padding: 5px 12px;
  font-size: 1.75rem;
  color: var(--white);
  background-color: var(--secondary-color-dark);
  border-radius: 5px;
}

.join__card h4 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.join__card p {
  color: var(--text-light);
}

.price__grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.price__card {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-light);
  border: 3px solid transparent;
  border-radius: 25px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  transition: transform 250ms;
}
.price__card2 {
  height: 100%;
  width: 100%;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  border: 3px solid transparent;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: 0.3s;
  transition: transform 250ms;
}

.price__card:hover {
  background-color: var(--primary-color-extra-light);
  transform: translateY(-10px);
}

.price__card2:hover {
  background-color: var(--secondary-color-dark);
  transform: translateY(-10px);
}
.price__card__content {
  flex: 1;
  margin-bottom: 2rem;
}
.price__card__content2 {
  flex: 1;
  margin-bottom: 2rem;
}
.price__card h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.price__card2 h4 {
  margin-bottom: 0.3rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.price__card h3 {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  border-bottom: 2px solid var(--white);
  text-align: center;
}

.price__card2 h3 {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  border-bottom: 2px solid var(--white);
  text-align: center;
}
.price__card p {
  margin-bottom: 0.75rem;
  color: #fff;
}

.price__card2 p {
  margin-bottom: 0.75rem;
  color: #fff;
}
.price__card p i {
  margin-right: 0.5rem;
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.price__card2 p i {
  margin-right: 0.5rem;
  font-size: 1.2rem;
  color: var(--secondary-color);
}
.price__btn {
  color: var(--secondary-color);
  background-color: transparent;
  border: 2px solid var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.price__btn2 {
  color: var(--secondary-color);
  background-color: transparent;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.price__btn:hover {
  color: var(--white);
  background-color: var(--secondary-color);
}

.price__btn2:hover {
  color: var(--white);
  background-color: var(--secondary-color);
}

.espacioNegocios {
  margin-bottom: 5%;
}


.review {
  background-color: var(--primary-color);
}
.gradient-border {
  border-radius: 5px solid;
  border: 2px solid #0000;
  background:
    linear-gradient(black, #222) padding-box,
    linear-gradient(#f9bd59, #ffde59) border-box;
}

.contacto {
  background-color: white;
}

.review__container {
  display: flex;
  gap: 2rem;
  background-image:  url('assets/fondoContacto.png');
  background-size: 100%; /* Reducir el tamaño de la imagen al 80% del contenedor */
  background-position: center; /* Centra la imagen dentro del contenedor */
  border-radius: 5px; /* Bordes iniciales */
  border-top-color: #ffde59;
  border-left-color: #f9ac54;
  border-right-color: #f9ac54;
  border-bottom-color: #ffde59;
  transition: border-radius 0.3s ease; /* Transición suave */
  border: 2px solid #0000;
}
  
.review__container:hover {
  border-radius: 50px; /* Bordes redondeados al pasar el mouse */
  border-top-color: #ffde59;
  border-left-color: #f9ac54;
  border-right-color: #f9ac54;
  border-bottom-color: #ffde59;
}


.review__content {
  flex: 1;
}

.review__content h4 {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--white);
}

.review__content h2 {
  margin-top: -50px;
  margin-bottom: 40px;
  font-size: 2.20rem;
  font-weight: 600;
  text-align: center;
  color: white;
}

.review__content p {
  max-width: 800px;
  margin-bottom: 2rem;
  color: var(--text-light);
}

.review__rating span {
  font-size: 1.5rem;
  color: var(--secondary-color);
}

.review__footer {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.review__member {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.review__member img {
  max-width: 60px;
  border-radius: 100%;
}

.review__member__details h4 {
  margin-bottom: 0;
}

.review__nav {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.review__nav span {
  font-size: 2rem;
  color: var(--secondary-color);
  cursor: pointer;
}

.footer__container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr repeat(3, minmax(0, 1fr)); /* Utilizando fr y minmax para hacer que el primer elemento ocupe todo el espacio restante */
  gap: 2rem;
  box-shadow: 0px 0px 10px #f9ac54; 
  margin-top: 1%;
  width: 100%; 
}


.footer__blur {
  bottom: 0;
  right: 0;
}

.footer__logo {
  max-width: 150px;
  margin-bottom: 2rem;
}

.footer__col p {
  margin-bottom: 2rem;
  color: var(--text-light);
}

.footer__socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__socials a {
  padding: 5px 10px;
  font-size: 1.25rem;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 100%;
  transition: 0.3s;
}

.footer__socials a:hover {
  color: var(--white);
  background-color: var(--secondary-color);
}

.footer__col h4 {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: orange;
}

.footer__col > a {
  display: block;
  margin-bottom: 1rem;
  color: black;
  transition: 0.3s;
}

.footer__col > a:hover {
  color: var(--secondary-color);
}

.footer__bar {
  max-width: var(--max-width);
  margin: auto;
  padding: 1rem;
  font-size: 0.8rem;
  color: var(--white);
}

.slick-slider {
  max-width: 900px; /* Establece el ancho máximo del carrusel */
  margin: 0 auto; /* Centra el carrusel en la página */
}


.slick-slide img {
  width: 100%; /* Hace que las imágenes ocupen el 100% del ancho del contenedor */
  height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
}

form {
  display: flex;
  flex-direction: column;
  width: 40%; /* Ajusta el ancho según tus necesidades */
  height: auto;
  margin: auto;
  background-color: #35373b;
  padding: 20px;
  border-radius: 25px;
}

label {
  color: #f9ac54;
  margin-bottom: 5px;
}

input,
textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #f9ac54;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}

input[type="submit"] {
  margin-top: 10px;
  background-color: #f9ac54;
  color: #fff;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #d88a3b;
}


.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}

.cookie-button {
  background-color: #d79447; /* Color de fondo del botón */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px; /* Forma redondeada */
  outline: none; /* Elimina el borde al hacer clic */
  transition: background-color 0.3s; /* Transición suave */
  margin-left:5%;
}

.cookie-button:hover {
  background-color: #b9823e; /* Cambia el color de fondo al pasar el ratón */
}

.cookie-button span {
  margin-left: 5px; /* Espacio entre el texto y el emoji de galleta */
}

.cookie-emoji {
  font-size: 35px; /* Tamaño del emoji */
  vertical-align: middle; /* Alinear verticalmente */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.banner button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.banner p {
  margin-top: 20px;
}

.txtAgencia {
  font-size: 17px;
  font-weight: bold;
}
@media (width < 900px) {
  .nav__links {
    font-size:15px;
    text-align: center;
  }

  .nav__logo{
    padding: 5px;
    margin-left: 1%;
    width:25%;
  }

  .header__container {
    grid-template-columns: repeat(1, 1fr);
  }

  .header__content {
    margin-top:7%
  }

  .header__image {
    grid-area: 1/1/2/2;
  }
  .btn_header {
    width:100%; 
    text-align: center;
  }
  .txtAgencia {
    font-size: 16px;
    font-weight: bold;
  }

  .explore__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .class__container {
    grid-template-columns: repeat(1, 1fr);
    
  }

  .class__image {
    min-height: 500px;
  }

  .price__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .review__container {
    gap: 2rem;
  }

  .footer__container {
    grid-template-columns: 1fr 200px;
  }

  .loader{
    margin-top: 50%;
  }

  .espacioNegocios {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .cookie-banner {
    margin-top: 30%;
    margin-bottom: 0%;
  }
  .privacidad-container {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .gradient-border {
    width:80%;
  }
  .join__container {
    margin-bottom: -10%;
  }

  .class__image {
    position: relative;
  }
  
  .class__image .class__img-1 {
    position: absolute;
    right: 10%;
    top: 0;
    max-width: 500px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  
  .class__image .class__img-2 {
    position: absolute;
    left: 10%;
    bottom: 0;
    max-width: 300px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  

}

@media (width < 600px) {
  .nav__links {
    display: none; /* Oculta los enlaces de navegación en pantallas pequeñas */
    flex-direction: column;
    position: absolute;
    top: 100%; /* Coloca el menú justo debajo del navbar */
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
  }

  .nav__links.open {
    display: flex; /* Muestra los enlaces de navegación cuando el menú está abierto */
  }

  .nav__menu-icon {
    display: block; /* Muestra el ícono de hamburguesa en pantallas pequeñas */
    cursor: pointer;
    align-items: center; 
    font-size: 35px;
  }

  .nav__logo{
    padding: 5px;
    margin-left: 1%;
    width:25%;
  }
  .btn_header {
    width:90%; 
    text-align: center;
    font-size: 16px;
    padding-top: 8%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .header__content {
    margin-top:10%;
  }
  .txtAgencia {
    font-size: 15px;
  }

  .header__content h1 {
    margin-bottom: 1rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 6rem;
    color: var(--white);
    
  }

  .btn_nosotrosTeAyudamos{
    width: 100%;
  }
  .btn_diferenciate{
    width: 100%;
  }
  .btn_solicitaPresupuesto {
    width: 100%;

  }

  .explore__header {
    flex-direction: column;
  }

  .explore__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .join__container {
    margin-bottom: 15rem;
  }

  .join__grid {
    display: inline-block;
    width: 100%;
    margin: 0;
    bottom: -20rem;
  }

  .price__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .review__container {
    flex-direction: column;
    gap: 0;
  }

  .review__footer {
    flex-direction: column;
  }

  .footer__container {
    grid-template-columns: 1fr 150px;
  }

  .footer__bar {
    text-align: center;
  }

  .loader{
    margin-top: 80%;
    right:5%
  }
  .espacioNegocios {
    margin-top: 0%;
    margin-bottom: 60%;
  }
  .cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    padding: 15px;
    text-align: center;
    z-index: 9999;
    overflow-y: hidden;
    font-size: 12px; 

  }
  .privacidad-container {
    flex-direction: column;
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .join__container {
    margin-bottom: -10%;
  }

  .review__container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 2rem;
    background-image:  url('assets/fondoContacto.png');
    background-size: 163%; /* Reducir el tamaño de la imagen al 80% del contenedor */
    background-position: center; /* Centra la imagen dentro del contenedor */
    border-radius: 5px; /* Bordes iniciales */
    border-top-color: #ffde59;
    border-left-color: #f9ac54;
    border-right-color: #f9ac54;
    border-bottom-color: #ffde59;
    transition: border-radius 0.3s ease; /* Transición suave */
    border: 2px solid #0000;
  }

  .footer__image {
    width:30%;
    align-items: center;
  }

  .class__image {
    position: relative;
    margin-bottom: -50%;
  }
  
  .class__image .class__img-1 {
    position: relative;
    left: 20%;
    top: 0;
    max-width: 300px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  
  .class__image .class__img-2 {
    position: relative;
    left: 10%;
    bottom: 10%;
    max-width: 200px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  
}

@media (width < 400px) {
  .nav__links {
    display: none; /* Oculta los enlaces de navegación en pantallas pequeñas */
    flex-direction: column;
    position: absolute;
    top: 100%; /* Coloca el menú justo debajo del navbar */
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
  }

  .nav__links.open {
    display: flex; /* Muestra los enlaces de navegación cuando el menú está abierto */
  }

  .nav__menu-icon {
    display: block; /* Muestra el ícono de hamburguesa en pantallas pequeñas */
    cursor: pointer;
    align-items: center; 
    font-size: 35px;
  }

  .nav__logo{
    padding: 5px;
    margin-left: 1%;
    width:25%;
  }
  .btn_header {
    width:90%; 
    text-align: center;
    font-size: 16px;
    padding-top: 8%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .header__content {
    margin-top:10%;
  }
  .txtAgencia {
    font-size: 15px;
  }

  .header__content h1 {
    margin-bottom: 1rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 6rem;
    color: var(--white);
    
  }

  .btn_nosotrosTeAyudamos{
    width: 100%;
  }
  .btn_diferenciate{
    width: 100%;
  }
  .btn_solicitaPresupuesto {
    width: 100%;

  }

  .explore__header {
    flex-direction: column;
  }

  .explore__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .join__container {
    margin-bottom: 15rem;
  }

  .join__grid {
    display: inline-block;
    width: 100%;
    margin: 0;
    bottom: -20rem;
  }

  .price__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .review__container {
    flex-direction: column;
    gap: 0;
  }

  .review__footer {
    flex-direction: column;
  }

  .footer__container {
    grid-template-columns: 1fr 150px;
  }

  .footer__bar {
    text-align: center;
  }

  .loader{
    margin-top: 80%;
    right:5%
  }
  .espacioNegocios {
    margin-top: 0%;
    margin-bottom: 60%;
  }
  .cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    padding: 15px;
    text-align: center;
    z-index: 9999;
    overflow-y: hidden;
    font-size: 12px; 

  }
  .privacidad-container {
    flex-direction: column;
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .join__container {
    margin-bottom: -10%;
  }

  .review__container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 2rem;
    background-image:  url('assets/fondoContacto.png');
    background-size: 169%; /* Reducir el tamaño de la imagen al 80% del contenedor */
    background-position: center; /* Centra la imagen dentro del contenedor */
    border-radius: 5px; /* Bordes iniciales */
    border-top-color: #ffde59;
    border-left-color: #f9ac54;
    border-right-color: #f9ac54;
    border-bottom-color: #ffde59;
    transition: border-radius 0.3s ease; /* Transición suave */
    border: 2px solid #0000;
  }

  .footer__image {
    width:30%;
    align-items: center;
  }
  .class__image {
    position: relative;
    margin-bottom: -50%;
  }
  
  .class__image .class__img-1 {
    position: relative;
    left: 15%;
    top: 0;
    max-width: 280px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  
  .class__image .class__img-2 {
    position: relative;
    left: 5%;
    bottom: 10%;
    max-width: 200px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
}

@media (width < 360px) {
  .cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    text-align: center;
    z-index: 9999;
    overflow-y: hidden;
    font-size: 10px;
    
  }
  .txtAgencia {
    font-size: 15px;
  }

  .header__content h1 {
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 700;
    line-height: 6rem;
    color: var(--white);
    
  }

  .espacioNegocios {
    margin-top: 40%;
    margin-bottom: 60%;
  }

  .review__container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 2rem;
    background-image:  url('assets/fondoContacto.png');
    background-size: 197%; /* Reducir el tamaño de la imagen al 80% del contenedor */
    background-position: center; /* Centra la imagen dentro del contenedor */
    border-radius: 5px; /* Bordes iniciales */
    border-top-color: #ffde59;
    border-left-color: #f9ac54;
    border-right-color: #f9ac54;
    border-bottom-color: #ffde59;
    transition: border-radius 0.3s ease; /* Transición suave */
    border: 2px solid #0000;
  }
  .class__image {
    position: relative;
    margin-bottom: -70%;
  }
  
  .class__image .class__img-1 {
    position: relative;
    left: 10%;
    top: 0;
    max-width: 280px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  
  .class__image .class__img-2 {
    position: relative;
    left: 5%;
    bottom: 10%;
    max-width: 180px;
    border-radius: 10px;
    border-radius: 5px solid;
    border: 2px solid #0000;
    background:
      linear-gradient(black, #222) padding-box,
      linear-gradient(#f9bd59, #ffde59) border-box;
  }
  .loader{
    margin-top: 50%;
    right:5%
  }

  .privacidad-container {
    height: 15rem;
    
  }
  .privacidad-container h1 {
    font-size: smaller;
  }
  .privacidad-container h2 {
    font-size: smaller;
  }
  .privacidad-container a {
    font-size: smaller;
  }
}
